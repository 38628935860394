import { useState, useRef, useLayoutEffect } from 'react';
import classnames from 'classnames';

import {
    Card,
    HSpacingContainer,
    Link as MagritteLink,
    Text as MagritteText,
    Title,
    VSpacing as MagritteVSpacing,
} from '@hh.ru/magritte-ui';
import { EnvelopeOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { H3Section } from 'bloko/blocks/header';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import verticalFadeIn from 'Modules/verticalFadeIn';
import paths from 'src/app/routePaths';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import useGetResponseQuestion from 'src/components/VacancyView/hooks/useResponseQuestion';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';
import { EmployerStateType } from 'src/models/negotiationTopic.types';

import Description from 'src/components/Applicant/Response/CoverLetter/Description';
import CoverLetterForm from 'src/components/Applicant/Response/CoverLetter/Form';

import styles from './response-cover-letter.less';

const TrlKeys = {
    headLetter: 'vacancy.response.letter.informer.headLetter',
    headLetterAfterSend: 'vacancy.response.letter.informer.headLetterAfterSend',
    descriptionAfterSend: 'vacancy.response.letter.informer.descriptionAfterSend',
    headResponseSuccess: 'vacancy.response.letter.informer.headResponseSuccess',
    sentFromSearch: 'vacancy.response.letter.informer.sent.search',
    sentFromVacancy: 'vacancy.response.letter.informer.sent.vacancy',
    attachLetter: 'vacancy.response.letter.informer.attachLetter',
    addLetter: 'vacancy.response.letter.informer.addLetter',
    writeButton: 'vacancy.response.letter.informer.writeButton',
};

interface ResponseCoverLetterProps {
    vacancyId: number;
    setNextStep?: (value: boolean) => void;
    defaultView?: string;
    hasSeparator?: boolean;
    isRedesign?: boolean;
    isMagritteExp?: boolean;
    isMagritteOnVacancyResponseExp?: boolean;
}

const ResponseCoverLetter: TranslatedComponent<ResponseCoverLetterProps> = ({
    vacancyId,
    trls,
    setNextStep,
    defaultView,
    hasSeparator,
    isRedesign,
    isMagritteExp,
    isMagritteOnVacancyResponseExp,
}) => {
    const isMagritteCoverLetterFormOnVacancyPage = isMagritteOnVacancyResponseExp && !defaultView;
    const responseStatuses = useSelector((state) => state.applicantVacancyResponseStatuses);
    const isVacanciesMap = useSelector(({ router }) => router.location.pathname === paths.vacancySearchMap);
    const responseStatus = responseStatuses[vacancyId];
    const vacancy = responseStatus?.shortVacancy;
    const getVacancyResponseQuestion = useGetResponseQuestion();
    const responseQuestion = getVacancyResponseQuestion(vacancy?.vacancyId);
    const hasResponseQuestion = Boolean(responseQuestion);
    const [showLetterForm, setShowLetterForm] = useToggleState(hasResponseQuestion);
    const [isSend, setSuccessSend] = useState(false);
    const breakpoint = useBreakpoint(Breakpoint.M);
    const isXs = breakpoint === Breakpoint.XS;
    const letterRef = useRef(null);

    const { wideCardContainerClassName } = useCardContainerClassNames();

    useLayoutEffect(() => {
        if (defaultView && responseStatus) {
            if (letterRef.current) {
                const withoutPadding = isMagritteExp || isMagritteCoverLetterFormOnVacancyPage;
                const abortController = new AbortController();

                void verticalFadeIn(letterRef.current, {
                    hiddenClass: styles.letterHidden,
                    propertyList: {
                        height: undefined,
                        paddingTop: withoutPadding ? 0 : 20,
                        paddingBottom: withoutPadding ? 0 : 20,
                        opacity: 1,
                    },
                    abortSignal: abortController.signal,
                }).then(() => {
                    setNextStep?.(true);
                });

                return () => {
                    abortController.abort();
                };
            }
            if (!responseStatus.hasQuickResponse) {
                setNextStep?.(true);
            }

            return undefined;
        }

        return undefined;
    }, [setNextStep, responseStatus, defaultView, isMagritteExp, isMagritteCoverLetterFormOnVacancyPage]);

    if (!responseStatus?.negotiations?.topicList) {
        return null;
    }

    const lastTopic = responseStatus.negotiations.topicList.sort(
        (a, b) => b.creationTimeMillis - a.creationTimeMillis
    )[0];

    if (
        lastTopic?.lastState !== EmployerStateType.Response ||
        lastTopic?.viewedByOpponent ||
        (defaultView && !responseStatus.hasQuickResponse)
    ) {
        return null;
    }

    const hasResponseLetter =
        vacancy?.['@responseLetterRequired'] ||
        lastTopic?.resources?.map(({ type }) => type)?.includes('RESPONSE_LETTER');

    const showResponseLetter = !hasResponseLetter || (hasResponseQuestion && responseStatus.hasQuickResponse);

    const renderContent = () => {
        if (isSend) {
            if (defaultView) {
                return (
                    <>
                        <H3Section>{trls[TrlKeys.headLetter]}</H3Section>
                        <VSpacing base={1} />
                        <Text>{trls[TrlKeys.sentFromSearch]}</Text>
                    </>
                );
            }

            const sentTrl = trls[TrlKeys.sentFromVacancy];

            return isMagritteCoverLetterFormOnVacancyPage ? (
                <Card paddingLeft={12} paddingRight={12} stretched>
                    <MagritteText typography="label-3-regular">{sentTrl}</MagritteText>
                </Card>
            ) : (
                <Text>{sentTrl}</Text>
            );
        }

        if (showLetterForm || isMagritteCoverLetterFormOnVacancyPage) {
            return (
                <CoverLetterForm
                    setSuccessSend={setSuccessSend}
                    letterMaxLength={responseStatus.letterMaxLength}
                    topicId={lastTopic.id}
                    submitButtonStretched={!defaultView && isXs}
                    initialText={responseQuestion?.value}
                    isMagritteCoverLetterFormOnVacancyPage={isMagritteCoverLetterFormOnVacancyPage}
                />
            );
        }

        return defaultView ? (
            <>
                <H3Section>{trls[TrlKeys.headResponseSuccess]}</H3Section>
                <VSpacing base={1} />
                <Text>
                    <Description
                        responseStatus={responseStatus}
                        lastTopic={lastTopic}
                        hasResponseLetter={hasResponseLetter}
                        defaultView={defaultView}
                    />
                </Text>
                <VSpacing base={2} />
                {showResponseLetter && (
                    <Link
                        appearance={LinkAppearance.Pseudo}
                        data-qa="vacancy-response-letter-toggle"
                        onClick={() => setShowLetterForm()}
                    >
                        {trls[TrlKeys.attachLetter]}
                    </Link>
                )}
            </>
        ) : (
            showResponseLetter && (
                <>
                    <Text>
                        <Description responseStatus={responseStatus} lastTopic={lastTopic} />
                    </Text>
                    <VSpacing base={isRedesign ? 4 : 2} />
                    <Button
                        kind={ButtonKind.Primary}
                        data-qa="vacancy-response-letter-toggle"
                        onClick={() => setShowLetterForm()}
                        stretched={isXs}
                    >
                        {trls[TrlKeys.writeButton]}
                    </Button>
                </>
            )
        );
    };

    const renderMagritteContent = () => {
        return (
            <>
                {!showLetterForm && (
                    <>
                        <Title
                            size="small"
                            description={
                                isSend ? (
                                    trls[TrlKeys.descriptionAfterSend]
                                ) : (
                                    <Description
                                        responseStatus={responseStatus}
                                        lastTopic={lastTopic}
                                        hasResponseLetter={hasResponseLetter}
                                        defaultView={defaultView}
                                        isMagritteExp
                                    />
                                )
                            }
                            Element="h4"
                        >
                            {trls[isSend ? TrlKeys.headLetterAfterSend : TrlKeys.headResponseSuccess]}
                        </Title>
                        {!isSend && showResponseLetter && (
                            <>
                                <MagritteVSpacing default={12} />
                                <MagritteLink
                                    typography="label-3-regular"
                                    Element="button"
                                    data-qa="vacancy-response-letter-toggle"
                                    onClick={setShowLetterForm}
                                >
                                    {trls[TrlKeys.addLetter]}
                                </MagritteLink>
                            </>
                        )}
                    </>
                )}
                <CoverLetterForm
                    setSuccessSend={setSuccessSend}
                    letterMaxLength={responseStatus.letterMaxLength}
                    topicId={lastTopic.id}
                    initialText={responseQuestion?.value}
                    isMagritteExp
                    onCloseForm={setShowLetterForm}
                    showLetterForm={showLetterForm}
                />
            </>
        );
    };

    if (defaultView === 'search' && isMagritteExp) {
        return (
            <>
                <MagritteVSpacing default={12} />
                <div className={styles.letterHidden} ref={letterRef}>
                    <Card style="warning" borderRadius={12} padding={24} stretched>
                        <HSpacingContainer default={24}>
                            <div className={wideCardContainerClassName}>
                                {/* TODO: выпилить кастомный компонент https://jira.hh.ru/browse/PORTFOLIO-32092 */}
                                <div className={styles.letterIconContainer}>
                                    <EnvelopeOutlinedSize24 initial="warning-secondary" />
                                </div>
                            </div>
                            <div className={styles.letterContentContainer}>{renderMagritteContent()}</div>
                        </HSpacingContainer>
                    </Card>
                </div>
            </>
        );
    }

    if (defaultView) {
        return (
            <>
                <VSpacing base={4} xs={6} />
                <div
                    className={classnames(styles.letter, {
                        [styles.letterHidden]: true,
                        [styles.letterVacancyBody]: defaultView === 'vacancybody',
                        [styles.letterSearch]: defaultView === 'search',
                    })}
                    ref={letterRef}
                >
                    <div
                        className={classnames(styles.letterWrapper, {
                            [styles.letterWrapperNarrow]: isVacanciesMap,
                        })}
                        data-qa="vacancy-response-letter-informer"
                    >
                        <div className={styles.letterIcon} />
                        <div
                            className={classnames({
                                [styles.letterContent]: true,
                                [styles.letterContentSuccess]: isSend,
                            })}
                        >
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    if (showResponseLetter) {
        return (
            <>
                {hasSeparator && (
                    <div className={styles.separator}>
                        <VSpacing base={4} />
                    </div>
                )}
                <VSpacing base={4} />
                <div data-qa="vacancy-response-letter-informer">{renderContent()}</div>
            </>
        );
    }
    return null;
};

export default translation(ResponseCoverLetter);
